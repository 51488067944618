// extracted by mini-css-extract-plugin
export var active = "navbar-module--active--e4519";
export var contactUs = "navbar-module--contactUs--298f5";
export var items = "navbar-module--items--d3006";
export var joinOurTeam = "navbar-module--joinOurTeam--babd6";
export var logo = "navbar-module--logo--7e264";
export var menuIcon = "navbar-module--menuIcon--96e8e";
export var navAnimation = "navbar-module--navAnimation--277d8";
export var navContent = "navbar-module--navContent--4c075";
export var navItem = "navbar-module--navItem--e85a0";
export var navItemContainer = "navbar-module--navItemContainer--620c1";
export var scrollDown = "navbar-module--scrollDown--85f22";
export var scrollUp = "navbar-module--scrollUp--9a23a";
export var slideInFromRight = "navbar-module--slideInFromRight--f22f3";
export var slideOutToRight = "navbar-module--slideOutToRight--ce5db";
export var welcome = "navbar-module--welcome--8bff1";